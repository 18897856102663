import * as React from 'react';
import MyMarker from '../../components/Marker';
import { ObjectCard } from '../../components/ObjectCard';
import { UpdatedTs } from '../../util/FormattingUtils';
import { Box } from '@mui/system';


const endpoint = "train_crossings";

const showCardContent = (obj) => { return true }

const getIcon = (icon, zoom) => {
    let url = "/mapicons/train/RailRoadCrossing_green.png";
    if(icon.blocked) {
        url = "/mapicons/train/RailRoadCrossing_red.png"
    }
    let size = new window.google.maps.Size(30, 30);
    let anchor = new window.google.maps.Point(15, 15);
    if (zoom < 11 && zoom > 8) {
        size = new window.google.maps.Size(15, 15);
        anchor = new window.google.maps.Point(9, 9);
    } else if(zoom <= 8) {
        size = new window.google.maps.Size(10, 10);
        anchor = new window.google.maps.Point(5, 5);
    }
    return {
        url: url,
        anchor: anchor,
        scaledSize: size
    };
}

const TCMarker = (props) => {
    const currentZoom = props.currentZoom;
    const icondef = getIcon(props.icon, currentZoom);
    return (
        <MyMarker {...props} icondef={icondef} />
    )
}

const InfoWindow = (props) => {
    const obj = props.icon;
    return (
        <div className="infoWindow center">
        <div>Train Crossing {obj.blocked?"Blocked":"Clear"}</div>
        <div><Box sx={{ fontWeight: 'bold'}}>{obj.startPoint.route}</Box></div>
        <hr></hr>Updated: <UpdatedTs value={obj.timestamp} /></div>
    )
}

export default {
    name: 'Crossings',
    label: 'Train Crossing #',
    marker: TCMarker,
    showOnMap: true, //show on map layer filter
    mapLayerIcon: "/mapicons/train/RailRoadCrossing_red.png",
    showOnSidebar: false,
    sideBarCard: ObjectCard,
    endpoint: endpoint,
    defaultOn: false,
    updateDelay: 0,
    showCardContent: showCardContent,
    infoWindow: InfoWindow,
    publishLayer: "tasWebapp.traincrossingicons.enable",
    resizeOnZoom: true
}