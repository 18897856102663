import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import LayersContext from '../context/LayersContext';
import { blueGrey } from '@mui/material/colors'

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 58,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: .2,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
  },
  margin: '5px',
  borderRadius: '5px',
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 2,
  height: "35px",
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain'
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '10px'
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.0,
  transition: theme.transitions.create('opacity'),
  padding: '10px',
  borderRadius: '5px',
}));

export default function LayerButton(props) {
  const layersContext = React.useContext(LayersContext);
  const module = props.module;
  const title = module.name;
  const [on, setButtonOn] = React.useState(undefined !== layersContext[title]?layersContext[title]:module.defaultOn);
  const url = module.mapLayerIcon;
  const setLayersOn = props.setLayersOn;

  let style = {
    maxWidth: "100px",
    width: "100px",
    boxShadow: '0px 0px 3px rgba(0,0,0,0.5)',
  };

  var hasIcon = (url.trim() !== "");

  if(on) {
    style.backgroundColor = "rgb(255,255,255,0.8)"
  } 

  const onClick = (e) => {
    setButtonOn(!on);
  }

  React.useEffect(()=>{
    setLayersOn(layersContext => {
      let newLayersContext = {...layersContext};
      newLayersContext[title] = on;
      return newLayersContext;
    })
  },[on,title])

  return (
    <ImageButton
      focusRipple
      key={title}
      style={style}
      onClick={onClick}
    >
      <ImageSrc style={{ backgroundImage: `url(${url})` }} />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image />
      <Typography
        component="span"
        variant="body3"
        color="black"
        sx={{
          position: 'absolute',
          top: hasIcon?0:'20%',
          pb: 0,
          color: on?blueGrey[900]:"white"
        }}
      >
        {title}
      </Typography>
    </ImageButton>
  );
}