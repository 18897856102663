import React, { forwardRef } from 'react'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Alert = forwardRef((props, ref) => {
    return <MuiAlert elevation={6} variant="filled" {...props} ref={ref} sx={{width:"100%"}}/>
});

export default function CustomAlert(props) {
    const isOpen = props.showAlert;
    const message = props.message;
    const severity = props.severity;
    const setShowAlert = props.setShowAlert;
    const setMessage = props.setMessage;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleClose = (event) => {
        setShowAlert(false);
    };

    React.useEffect(() => {
        if ("" !== message) {
            setShowAlert(true);
        }
    }, [message])

    React.useEffect(() => {
        if(!isOpen) {
            setMessage("");
        }
    },[isOpen])


    if(matches) {
        return (
            <Snackbar open={isOpen} onClose={handleClose} anchorOrigin={{vertical:'top', horizontal:'center'}} sx={{width:"50%"}} autoHideDuration={6000}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        )
    } else {
        return (
            <Snackbar open={isOpen} onClose={handleClose} anchorOrigin={{vertical:'top', horizontal:'center'}}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        )
    }
}