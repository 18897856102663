
import MyMarker from "../../../components/Marker"
import { eventIcon } from "../../../util/EventIcons";

const RoadworkMarker = (props) => {
    const endpoint = props.module.endpoint;
    const currentZoom = props.currentZoom;
    const icondef = eventIcon(props.icon, endpoint,currentZoom);
    return (
        <MyMarker {...props} icondef={icondef} />
    )
}

export {
    RoadworkMarker
}