import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Header from './components/Header';
import Map from './components/Map'
import SideDrawer from './components/SideDrawer';
import modules from './modules';
import DataCacheContext from './context/DataCacheContext';
import Footer from './components/Footer'
import { LayerProvider } from './context/LayersContext'
import { SearchProvider } from './context/SearchContext'
import { DomainProvider } from "./context/DomainContext";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserFooter from "./modules/User/UserFooter";
import DomainContext from './context/DomainContext';
import { setDomain } from './util/ArrayUtil';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { UpdatedTs } from './util/FormattingUtils';

let newUser = {
  "username": "",
  "emailAddress": "",
  "name": "",
  "contentType": "text/html",
  "primary": true,
  "profile": true,
  "vehicles": [],
  "profiles": []
}

export default function Layout(props) {
  const [domains, setDomains] = React.useState(null);
  const dataCache = React.useContext(DataCacheContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isUser, setIsUser] = React.useState();
  const [footerSelection, setFooterSelection] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [refreshUser, setRefreshUser] = React.useState(false);
  const testApi = process.env.REACT_APP_EXTERNAL_API === "true";
  const isDark = props.isDark;
  const switchTheme = props.switchTheme;
  const lastUpdate = props.lastUpdate;

  /*React.useEffect(() => {
    const checkLoggedIn = async () => {
      const res = await fetch(process.env.REACT_APP_API_HOST + "/loggedin", {
      });
      res.json().then(res => {
        if (res.success) {
          setLoggedIn(true);
          setUser(res.object)
        }
      })
    }
    checkLoggedIn();
  }, [])
  
  const getUser = async () => {
    const res = await fetch(process.env.REACT_APP_API_HOST + "/account/user", {
      method: 'GET',
      withCredentials: true,
      headers: {
      'Authorization': 'Basic ' + btoa(process.env.REACT_APP_AUTH),
      'Accept': 'application/json',
      'Content-Type': 'application/json'}
    });
    res.json().then(res => {
      setUser(res);
      setRefreshUser(false);
    })
  }

  React.useEffect(() => {
    if(!loggedIn && testApi) getUser();
    if(loggedIn && refreshUser) getUser();
  }, [loggedIn, refreshUser])*/

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  /*React.useEffect(() => {
    setMobileOpen(matches);
  }, [])*/

  React.useEffect(() => {
    if (!dataCache.data['properties']) {
      dataCache.createDataCache('properties', 10);
    } else {
      console.log("adding event listener for domains");
      dataCache.data['properties'].addEventListener('message', function (e) {
        console.log("calling event listener for domains");
        setDomains(e.data);
      })
      dataCache.data['properties'].postMessage({ msg: "resume" });
    }
  }, [dataCache]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const footerStyle = (matches ? { position: "relative", zIndex: (theme) => theme.zIndex.drawer + 1 } : { position: "relative" });

  return (
    <Box sx={{ height: "100%", m:0, p:0 }}>
      <CssBaseline />
      <BrowserRouter>
        <DomainProvider value={domains}>
          <Box sx={{ height: 87 }}>
            <Header switchTheme={switchTheme} handleDrawerToggle={handleDrawerToggle} loggedIn={loggedIn} isUser={isUser} />
          </Box>
          <Box sx={{}}>
            <Routes>
              <Route exact path="/" element={<MainComponent loggedIn={loggedIn} mobileOpen={mobileOpen} isDark={isDark} handleDrawerToggle={handleDrawerToggle} setIsUser={setIsUser} user={user} 
              footerSelection={footerSelection} setFooterSelection={setFooterSelection} lastUpdate={lastUpdate}/>} />
            </Routes>
          </Box>
          <Box className="lastUpdate" sx={{flexGrow: 0, color: theme.palette.text.secondary }}>Last Updated &mdash; <UpdatedTs value={lastUpdate}></UpdatedTs></Box>
          <Box sx={footerStyle}>
            {isUser && <UserFooter setFooterSelection={setFooterSelection} footerSelection={footerSelection} />}
            {undefined !== isUser && !isUser && <Footer setFooterSelection={setFooterSelection} footerSelection={footerSelection} lastUpdate={lastUpdate} />}
          </Box>
        </DomainProvider>
      </BrowserRouter>
    </Box>
  );
}

const MainComponent = (props) => {
  const domains = React.useContext(DomainContext);
  const [layersOn, setLayersOn] = React.useState({});
  const [showMarquee, setShowMarquee] = React.useState(true);
  const [marqueeText, setMarqueeText] = React.useState("");
  const [searchFilters, setSearchFilters] = React.useState({});
  const loggedIn = props.loggedIn;
  const mobileOpen = props.mobileOpen;
  const isDark = props.isDark;
  const handleDrawerToggle = props.handleDrawerToggle;
  const setIsUser = props.setIsUser;
  React.useEffect(() => {
    setDomain(domains, "tasWebapp.eventscroller.enable", setShowMarquee);
  }, [domains])

  React.useEffect(() => {
    setIsUser(false);
  }, [])

  return (
    <SearchProvider value={searchFilters}>
      <LayerProvider value={layersOn}>
        <Map modules={modules} setLayersOn={setLayersOn} showMarquee={showMarquee} setMarqueeText={setMarqueeText} marqueeText={marqueeText} mobileOpen={mobileOpen} {...props} />
        <SideDrawer modules={modules} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} isDark={isDark} setSearchFilters={setSearchFilters} />
      </LayerProvider>
    </SearchProvider>
  )
}
