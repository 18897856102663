import React, { useState, useEffect, useRef } from 'react'
import { GoogleMap, LoadScript, TrafficLayer, useGoogleMap } from '@react-google-maps/api';
import useScreenOrientation from 'react-hook-screen-orientation'
import EndpointLayer from './EndpointLayer';
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import LayerButton from './ImageButton'
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { LayersControl, LayersButton } from './MapLayerControl';
import { DirectionsButton, DirectionsControl } from './MapDirectionsControl';
import DomainContext from '../context/DomainContext';
import TasMarquee from './TasMarquee'
import LayersContext from '../context/LayersContext';
import getMapType from './WmsMapType'
import _ from 'lodash'
import { setDomain, publishLayer } from '../util/ArrayUtil';
import PopupInfoWindow from './PopupInfoWindow';
import PlacesSearchBox from './PlacesSearchBox';
import CustomAlert from './CustomAlert';
import GeoJsonLayer from './GeoJsonLayer';

const containerStyle = {
    height: "100%",
    width: "100%"
};

const MapControl = (props) => {
    const map = useGoogleMap();
    const ref = useRef();
    const position = props.position;

    useEffect(() => {
        if (map && ref) {
            map.controls[window.google.maps.ControlPosition[position]].push(
                ref.current
            );
        }
    }, [map, ref, position])
    return <span ref={ref}>{props.children}</span>;
}

const libraries = ["places"];

function Map(props) {
    const layersContext = React.useContext(LayersContext);
    const [layers, setLayers] = React.useState([]);
    const [styles, setStyles] = React.useState([]);
    const [layerButtonOpen, setLayerButtonOpen] = React.useState(false);
    const [directionsButtonOpen, setDirectionsButtonOpen] = React.useState(false);
    const [map, setMap] = React.useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [apiKey, setApiKey] = React.useState(null);
    const [trafficLayer, setTrafficLayer] = React.useState(true);
    const [showWMS, setShowWMS] = React.useState(false);
    const screenOrientation = useScreenOrientation();
    const [center, setCenter] = useState(screenOrientation.includes("landscape") ? { lat: 41.83730086220008, lng: -87.66876030777101 } : { lat: 41.848527243750254, lng: -87.67578023476486 });
    const [refreshWms, setRefreshWms] = React.useState(false);
    const zoom = screenOrientation.includes("landscape") ? 11 : 10;
    const modules = props.modules;
    const domains = React.useContext(DomainContext);
    const loggedIn = props.loggedIn;
    const setLayersOn = props.setLayersOn;
    const footerSelection = props.footerSelection;
    const setFooterSelection = props.setFooterSelection;
    const [showAlert, setShowAlert] = React.useState(false);
    const [message, setMessage] = React.useState("")
    const [severity, setSeverity] = React.useState("success");
    const [currentZoom, setCurrentZoom] = React.useState(zoom);
    const mobileOpen = props.mobileOpen;
    const [options, setOptions] = React.useState(null);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const isLandscape = screenOrientation.includes("landscape") ;

    useEffect(() => {
        if (footerSelection && footerSelection === 2) {
            const getLocation = () => {
                if (!navigator.geolocation) {
                    setMessage('Geolocation is not supported by your browser');
                    setSeverity("warning");
                    setFooterSelection(null);
                } else {
                    setMessage('Locating...');
                    setSeverity("success");
                    navigator.geolocation.getCurrentPosition((position) => {
                        setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
                    }, () => {
                        setMessage('Unable to retrieve your location');
                        setSeverity("warning");
                        setFooterSelection(null);
                    });
                }

            }
            getLocation();
        }
    }, [footerSelection])


    useEffect(() => {
        let module = modules.find(module => module.name === "Google Traffic")
        if (module) {
            setTrafficLayer(module.defaultOn);
        }
        let modulesOn = modules.filter(module => module.defaultOn);
        modulesOn.forEach(module => layersContext[module.name] = module.defaultOn)
    }, [modules])

    useEffect(() => {
        const fetchApi = async () => {
            const res = await fetch('static/mapstyle.json', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            res.json()
                .then(res => {
                    setOptions({
                        styles: res
                    })
                })
        }
        fetchApi().catch(err => {
            console.log(err);
        });
        setInterval(() => {
            setRefreshWms(true);
        }, 180000)
    }, []);

    const resetWmsLayer = (map, layers, styles) => {
        if (null != map && layers.length > 0) {
            var wmsLayer = new window.google.maps.ImageMapType(getMapType(process.env.REACT_APP_GEOSERVER_HOST, layers.join(","), styles.join(",")));
            for (var i = 0; i < map.overlayMapTypes.length; i++) {
                var eLayer = map.overlayMapTypes.getAt(i);
                if (eLayer.name === wmsLayer.name) {
                    map.overlayMapTypes.removeAt(i);
                    break;
                }
            }
            map.overlayMapTypes.push(wmsLayer)
        }
        if(map && layers.length === 0) {
            for (var i = 0; i < map.overlayMapTypes.length; i++) {
                map.overlayMapTypes.removeAt(i);
            }
        }
    }

    useEffect(() => {
        if (refreshWms) {
            resetWmsLayer(map, layers, styles);
        }
        setRefreshWms(false);
    }, [refreshWms, map, layers])

    useEffect(() => {
        if (undefined !== layersContext['Google Traffic']) {
            setTrafficLayer(layersContext['Google Traffic']);
        }
    }, [layersContext['Google Traffic']])

    useEffect(() => {
        var wmsLayers = [];
        var wmsStyles = [];
        if (showWMS) {
            modules.map(module => {
                if (module.wmsMapLayer && undefined != layersContext[module.name] && layersContext[module.name] && publishLayer(module.wmsPublish, domains)) {
                    wmsLayers.push(module.wmsMapLayer)
                    if (module.wmsMapStyle) {
                        wmsStyles.push(module.wmsMapStyle)
                    }
                }
            })
        }
        if (!_.isEqual(wmsLayers, layers)) {
            setLayers(wmsLayers);
        }
        if (!_.isEqual(wmsStyles, styles)) {
            setStyles(wmsStyles);
        }
    }, [layersContext, showWMS])

    useEffect(() => {
        resetWmsLayer(map, layers, styles);
    }, [map, layers, styles])

    useEffect(() => {
        setDomain(domains, "tasWebapp.map.googleApiKey", setApiKey);
        setDomain(domains, "tasWebapp.wmsLayers.enable", setShowWMS);
    }, [domains])

    useEffect(() => {
        if (null != map) {
            map.addListener("zoom_changed", () => {
                setCurrentZoom(map.getZoom());
            })
        }
    }, [map])

    const onCloseClick = () => {
        setLayerButtonOpen(layerButtonOpen => !layerButtonOpen)
    }

    const onLoad = (gmap) => {
        let mapOptions = {
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.BOTTOM_CENTER,
            },
            zoomControl: true,
            zoomControlOptions: {
                position: window.google.maps.ControlPosition.RIGHT_TOP,
            },
            streetViewControlOptions: {
                position: window.google.maps.ControlPosition.RIGHT_TOP
            },
            scaleControl: false,
            streetViewControl: true,
            fullscreenControl: false,
            clickableIcons: false,
            minZoom: matches?11:isLandscape?9:10,
            maxZoom: 17
        }
        setOptions({ ...options, ...mapOptions });
        gmap.setOptions(options);
        gmap.addListener("dragend", (e) => {
            setCenter(gmap.getCenter());
        });
        setMap(gmap);
    }

    const loadmap = (
        <>
            <Box component="div" sx={{ flexGrow: 0 }}>
                <TasMarquee {...props} />
            </Box>
            <Box component="div" sx={{ flexGrow: 1 }}>
                <LoadScript
                    googleMapsApiKey={apiKey}
                    libraries={libraries}
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        options={options}
                        onLoad={onLoad}
                    >
                        {trafficLayer ? <TrafficLayer /> : null}
                        {modules.map(module => {
                            if (module.showOnMap && module.endpoint) {
                                return (
                                    <EndpointLayer setLayersOn={setLayersOn} module={module} key={module.name} selectedMarker={selectedMarker} setSelectedMarker={setSelectedMarker} setRefreshWms={setRefreshWms} currentZoom={currentZoom} />
                                )
                            }
                        })}
                        {modules.map(module => {
                            if (module.showOnMap && module.geoJsonUrl) {
                                return (
                                    <GeoJsonLayer module={module} setLayersOn={setLayersOn} key={module.name} />
                                )
                            }
                        })}
                            <>
                                <MapControl position={"RIGHT_BOTTOM"}>
                                    <LayersButton {...props} layerButtonOpen={layerButtonOpen} setLayerButtonOpen={setLayerButtonOpen} />
                                </MapControl>
                                    <MapControl position="RIGHT_BOTTOM">{layerButtonOpen ? <LayersControl {...props} showWMS={showWMS} domains={domains} /> : null}</MapControl>
                                {loggedIn &&
                                    <MapControl position="LEFT_TOP">
                                        <DirectionsButton setDirectionsButtonOpen={setDirectionsButtonOpen} />
                                        {directionsButtonOpen ? <DirectionsControl {...props} /> : null}
                                    </MapControl>
                                }
                                {selectedMarker &&
                                    <PopupInfoWindow selectedMarker={selectedMarker} setSelectedMarker={setSelectedMarker} />
                                }

                                <MapControl position="TOP_LEFT">
                                    <PlacesSearchBox map={map} />
                                </MapControl>
                            </>
                    </GoogleMap>
                </LoadScript>
            </Box>
        </>
    )

    //below handles changes between phone and larger screen surfaces
    //layer selector pops open on large screen surfaces
    //slides up on small
    //      width: "calc(100vw - 350px)",
    // className={isDark ? "dark map" : "light map"}
    if (matches) {
        return (
            <>
                <CustomAlert showAlert={showAlert} message={message} severity={severity} setShowAlert={setShowAlert} setMessage={setMessage} />
                <Box component="div" sx={{
                    flexGrow: "2",
                    display: { xs: 'none', sm: 'none', md: 'flex' },
                    flexDirection: "column"
                }} className={mobileOpen? "map map-drawer":"map map-no-drawer"} >
                    {apiKey && options && loadmap}
                </Box>
            </>
        )
    } else {
        return (
            <>
                <CustomAlert showAlert={showAlert} message={message} severity={severity} setShowAlert={setShowAlert} setMessage={setMessage} />
                <Box component="div" sx={{
                    flexGrow: "2",
                    width: "100%",
                    display: { xs: 'flex', sm: 'flex', md: 'none' },
                    flexDirection: "column"
                }} className="map">
                    {apiKey && options && loadmap}
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: "100%", position: "absolute", bottom: 55, right: 0, left: 0 }}>
                    <Box component="div" sx={{
                        backgroundColor: "rgba(171, 183, 183,.8)",
                        zIndex: "1000"
                    }}>
                        <Slide direction='up' in={layerButtonOpen} mountOnEnter unmountOnExit>
                            <div>
                                <Box sx={{ display: "flex", color: "black" }}>
                                    <Typography
                                        component="div"
                                        variant="subtitle2"
                                        color="black"
                                        sx={{ flexGrow: 1, lineHeight: "30px", marginLeft: "5px" }}
                                    >
                                        Select Layers
                                    </Typography>
                                    <Box sx={{ flexGrow: 1, color: "black", textAlign: "right" }}>
                                        <IconButton aria-label="close" size="small" sx={{ color: "black" }} onClick={onCloseClick}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>

                                </Box>
                                {modules.map(module => {
                                    if (module.showOnMap) {
                                        return (
                                            <LayerButton {...props} module={module} key={module.name} />
                                        )
                                    }
                                })}
                            </div>
                        </Slide></Box>
                </Box>
            </>
        )
    }
}

export default Map