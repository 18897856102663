import { InfoWindow, Marker } from "@react-google-maps/api";
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { latlng } from "../util/FormattingUtils";


export default function PopupInfoWindow(props) {
    const selectedMarker = props.selectedMarker;
    const icon = selectedMarker.icon;
    const module = selectedMarker.module;
    const MyInfoWindow = module.infoWindow;
    const setSelectedMarker = props.setSelectedMarker;

    return (
        <InfoWindow
            position={latlng(selectedMarker.icon)}
            onCloseClick={() => {setSelectedMarker(null)}}
        >
            <MyInfoWindow module={module} icon={icon} />
        </InfoWindow>
    )

}