import * as React from 'react';
import MyMarker from '../../components/Marker';
import { ObjectCard } from '../../components/ObjectCard';
import styles from './Parking.module.css';

const endpoint = "parking";

const showCardContent = (obj) => { return true }

const ParkingMarker = (props) => {
    return (
        <MyMarker {...props} icondef={getIcon()} />
    )
}

const getIcon = () => {
    return {
        url: "/mapicons/parking/parking.png",
        anchor: new window.google.maps.Point(8, 8),
        scaledSize: new window.google.maps.Size(15, 15)
    };
}

const ParkingCardContent = (props) => {
    const obj = props.obj;
    return (
        <table cellSpacing={0} cellPadding={2} className={styles.locationTable}>
            <tbody>
                <tr>
                    <td>Location</td>
                    <td>{obj.proxy.address}</td>
                </tr>
                {obj.proxy.numSpaces > 1 ?
                    <tr>
                        <td>Spaces</td>
                        <td>{obj.proxy.numSpaces}</td>
                    </tr> : null
                }
                <tr><td>Phone #</td>
                <td>{obj.proxy.phone.replace("\"","").replace("\" ","")}</td></tr>
            </tbody>
        </table>
    )
}

const ParkingInfoWindow = (props) => {
    const module = props.module;
    const obj = props.icon;
    const MyCardContent = module.cardContent;
    const showCardContent = module.showCardContent;

    return (
        <div className="infoWindow"><ParkingHeader obj={obj} />
            {showCardContent(obj) ?
                <div className="spacer"><MyCardContent obj={obj} /></div>
                :
                null}
        </div>
    )
}

const ParkingHeader = (props) => {
    const obj = props.obj;
    return (
        <div>{obj.proxy.title}</div>
    )
}

export default {
    name: 'Parking',
    label: 'Parking Lot #',
    marker: ParkingMarker,
    showOnMap: true, //show on map layer filter
    mapLayerIcon: "/mapicons/parking/parking.png",
    showOnSidebar: false,
    sideBarCard: ObjectCard,
    endpoint: endpoint,
    defaultOn: false,
    updateDelay: 0,
    showCardContent: showCardContent,
    cardContent: ParkingCardContent,
    infoWindow: ParkingInfoWindow,
    cluster: true,
    clusterMarker: "/mapicons/signal/signal",
    clusterClass: "parking-cluster",
    publishLayer: "tasWebapp.parkingicons.enable"
}