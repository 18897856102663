const endpoint = "congestionregions";

export default {
    name: 'Congestion Outlook',
    label: 'Congestion Outlook',
    showOnMap: true, //show on map layer filter
    mapLayerIcon: "",
    showOnSidebar: false,
    endpoint: endpoint,
    defaultOn: false,
    updateDelay: 0,
    publishLayer: "tasWebapp.congestionregions.enable"
}