import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from "@mui/material/Box";
import DirectionsIcon from '@mui/icons-material/Directions';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput'

const ControlBox = styled('div')(({ theme }) => ({
    width: '100%', borderRadius: '5px', border: '5px', backgroundColor: theme.palette.background.default, color: "black", padding: "2px", boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px"
}));

const DirectionsControl = (props) => {
    const modules = props.modules;
    const [startTrip, setStartTrip] = React.useState(new Date());
    const [endTrip, setEndTrip] = React.useState(new Date());
    const [truckClass, setTruckClass] = React.useState(10);

    const handleChange = (newValue, func) => {
        if(func) func(newValue);
    };
    return (
        <>

            <Box sx={{ margin: "5px" }}>
                <ControlBox>

                    <AddressLookup title={"Enter Origin"} />
                    <AddressLookup title={"Enter Destination"} />
                    <Box component="div" sx={{ margin: '10px' }}>
                        <DateTimePicker
                            label="Trip Start"
                            value={startTrip}
                            onChange={(newValue) => handleChange(newValue, setStartTrip)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                    <Box component="div" sx={{ margin: '10px' }}>
                        <FormControl fullWidth>
                            <InputLabel id="truck-class">Truck Class</InputLabel>
                            <Select
                                labelId="truck-class"
                                id="truck-class"
                                value={truckClass}
                                label="Truck Class"
                                onChange={(newValue) => handleChange(newValue, setTruckClass)}
                            >
                                <MenuItem value={10}>Class 1</MenuItem>
                                <MenuItem value={20}>Class 2</MenuItem>
                                <MenuItem value={30}>Class 3</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box component="div" sx={{ margin: '10px' }}>
                    <FormControl>
                        <TextField
                            id="outlined-adornment-weight"
                            value={0}
                            onChange={handleChange('weight')}
                            endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                            label="Weight"
                        />
                    </FormControl>
                    </Box>
                    <Box component="div" sx={{ margin: '10px' }}>
                    <FormControl>
                        <TextField
                            id="outlined-adornment-weight"
                            value={0}
                            onChange={handleChange('weight')}
                            endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'height ft',
                            }}
                            label="Height(ft)"
                        />
                    </FormControl>
                    </Box>
                </ControlBox>
            </Box>
        </>
    )
}

const DirectionsButton = (props) => {
    const setDirectionsButtonOpen = props.setDirectionsButtonOpen;

    const handleClick = () => {
        setDirectionsButtonOpen(directionButtonOpen => { return !directionButtonOpen });
    }
    return (
        <Box sx={{ width: "100%", textAlign: "left", padding: "5px" }}>
            <Button variant="contained" size="large" aria-label="find-routes" endIcon={<DirectionsIcon />} onClick={handleClick} sx={{
                backgroundColor: "white", color: "rgba(0,0,0,0.6)", boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px", '&:hover': {
                    backgroundColor: "white",
                    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
                    color: "rgba(0,0,0,1)"
                },
            }}>
                Find Routes
            </Button>
        </Box>
    )
}

export {
    DirectionsButton,
    DirectionsControl
}

const filter = createFilterOptions();

const AddressLookup = (props) => {
    const [value, setValue] = React.useState(null);
    const title = props.title;

    return (
        <Box component="div" sx={{ margin: '10px' }}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            title: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            title: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={[]}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                }}
                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label={title} />
                )}
            />
        </Box>
    );
}
