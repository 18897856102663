import React from 'react';
import Moment from 'react-moment';
import { titleCase } from 'title-case';
import { Box } from '@mui/system';

const Date = props => {
    if(props.value) {
        return <Moment date={props.value} format="MMM Do, YYYY"></Moment>
    } 
    else return <></>
}

const DateText = props => {
    if(props.value) {
        return <Moment date={props.value} format="MMM Do YYYY&nbsp;HH:mm"></Moment>
    } 
    else return <></>
}

const DateTextSs = props => {
    if(props.value) {
        return <Moment date={props.value} format="MMM Do YYYY&nbsp;HH:mm:ss"></Moment>
    }
    else return <></>;
}

const checkTimestamp = (item, lastUpdate) => {
    if(item.timestamp && item.timestamp > lastUpdate) {
        return item.timestamp;
    }
    return lastUpdate;
}

const Updated = props => {
    const icon = props.icon;
    if(icon.lastUpdate) {
        return <div className="updated">Updated: {icon.lastUpdate}</div>
    }
    return null;
}

const UpdatedTs = props => {
    if(props.value) {
        return <Moment date={props.value} format="M/D/YYYY @ h:mm A"></Moment>
    }
}

const Location = props => {
    const obj = props.obj;
    if(undefined !== obj.startLocation) {
        return (
            <>
                <div className="route">{titleCase(obj.startLocation.route)}</div>
                <div className="xstreet">{obj.startLocation.xstreet}</div>
            </>

        )
    } else {
        return null;
    }

}

const latlng = (icon) => {
    return { lat: icon.iconPoint.latitude, lng: icon.iconPoint.longitude }
}

const MonitoredApproaches = (props) => {
    const camera = props.value.camera;
    var idx = 1;
    const approaches = [];
    while (undefined !== camera["approach" + idx]) {
        let approach = camera["approach" + idx++];
        if(approach && approach.trim() !== "") {
            approaches.push(approach)
        }
    }
    return (
        <div><Box sx={{ fontWeight: 'bold', display:"inline" }}>Monitored Approaches:&nbsp;</Box>{approaches.join(" / ")}</div>
    )
}

const CameraInstalled = props => {
    const icon = props.icon;
    if (icon.camera.goLive) {
        return <div className="updated">Date Installed: <Date value={icon.camera.goLive} /></div>
    }
    return null;
}

export {
    DateText,
    DateTextSs,
    Location,
    Updated,
    checkTimestamp,
    Date,
    latlng,
    MonitoredApproaches,
    CameraInstalled,
    UpdatedTs
}