import { Location, Updated } from "../../../util/FormattingUtils";

const IncidentInfoWindow = (props) => {
    const module = props.module;
    const obj = props.icon;
    const MyCardContent = module.cardContent;
    const showCardContent = module.showCardContent;

    return (
        <div className="infoWindow"><Location obj={obj}/>
        {showCardContent(obj)?
            <div className="spacer"><MyCardContent obj={obj}/></div>
            :
            null}
        <hr>
        </hr><Updated icon={obj} /></div>
    )
}

export {
    IncidentInfoWindow
}