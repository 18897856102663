import * as React from 'react';
import { titleCase } from 'title-case';
import MyMarker from '../../components/Marker';
import { ObjectCard } from '../../components/ObjectCard';
import styles from './RedLight.module.css';
import { MonitoredApproaches,CameraInstalled } from "../../util/FormattingUtils"

const endpoint = "redlightcameras";

const showCardContent = (obj) => { return true }

const RedLightMarker = (props) => {
    return (
        <MyMarker {...props} icondef={getIcon()} />
    )
}

const getIcon = () => {
    return {
        url: "/mapicons/redlightcamera/redlightcamera.png",
        anchor: new window.google.maps.Point(10, 10),
        scaledSize: new window.google.maps.Size(20, 20)
    };
}

const RedLightCardContent = (props) => {
    const obj = props.obj;
    return (
        <>
            <div className={styles.cameraName}>{titleCase(obj.camera.fullName.toLowerCase())}</div>
            <MonitoredApproaches value={obj} />
        </>
    )
}

const RedLightInfoWindow = (props) => {
    const module = props.module;
    const obj = props.icon;
    const MyCardContent = module.cardContent;
    const showCardContent = module.showCardContent;

    return (
        <div className="infoWindow center"><div>Red Light Camera Intersection</div>
            {showCardContent(obj) ?
                <div className="spacer"><MyCardContent obj={obj} /></div>
                :
                null}
            <hr>
            </hr><CameraInstalled icon={obj} /></div>
    )
}

export default {
    name: 'Red Light Cameras',
    label: 'Red Light Camera #',
    marker: RedLightMarker,
    showOnMap: true, //show on map layer filter
    mapLayerIcon: "/mapicons/redlightcamera/redlightcamera.png",
    showOnSidebar: false,
    sideBarCard: ObjectCard,
    endpoint: endpoint,
    defaultOn: false,
    updateDelay: 0,
    showCardContent: showCardContent,
    cardContent: RedLightCardContent,
    infoWindow: RedLightInfoWindow,
    publishLayer: "tasWebapp.redlightcameras.enable"
}