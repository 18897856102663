import { Location, Updated } from "../../../util/FormattingUtils";

const RoadworkInfoWindow = (props) => {
    const icon = props.icon;
    return (
        <div className="infoWindow"><Location obj={props.icon}/>
        <hr>
        </hr><Updated icon={props.icon} /></div>
    )
}

export {
    RoadworkInfoWindow
}