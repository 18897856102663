import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { titleCase } from 'title-case';

const getEventIconUrl = (icon,endpoint) => {
    var iconname = titleCase(endpoint) + "_";
    if (icon.impact) {
        if (icon.impact.toLowerCase() === "low") {
            iconname += "low.png"
        } else if (icon.impact.toLowerCase() === "medium") {
            iconname += "medium.png"
        } else if (icon.impact.toLowerCase() === "high" || icon.impact.toLowerCase() === "closed") {
            iconname += "highclosed.png"
        } else {
            iconname += "no.png"
        }
    } else {
        iconname += "no.png"
    }
    iconname = "/mapicons/event/" + iconname;
    return iconname
}

const eventIcon = (icon, endpoint, zoom) => {
    if(zoom >= 11) {
        return {
            url: getEventIconUrl(icon,endpoint),
            anchor: new window.google.maps.Point(17, 17),
            scaledSize: new window.google.maps.Size(35, 29)
        };
    } else if (zoom < 11 && zoom >= 8) {
        return {
            url: getEventIconUrl(icon,endpoint),
            anchor: new window.google.maps.Point(10, 10),
            scaledSize: new window.google.maps.Size(23, 19)
        };
    } else {
        return {
            url: getEventIconUrl(icon,endpoint),
            anchor: new window.google.maps.Point(7, 7),
            scaledSize: new window.google.maps.Size(17, 14)
        };
    }
}

const EventAvatar = React.forwardRef(function MyComponent(props, ref) {
    const d = props.value;
    const module = props.module;
    let imageUrl = getEventIconUrl(d, module.endpoint);
    let imageName = d.impact + " Impact";
    return <Avatar src={imageUrl} alt={imageName} {...props} ref={ref} variant="square" sx={{ height: '30px', width: '36px', objectFit:"contain" }} title={module.label  + d.displayId}/>
});

export {
    eventIcon,
    EventAvatar
}
