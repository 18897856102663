import React, { useState, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { DataCacheProvider  } from './context/DataCacheContext';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ReactGA from 'react-ga';
  


import Layout from './Layout';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDark, setIsDark] = React.useState(prefersDarkMode);
  const [es, setEs] = useState(null);
  const [lastUpdate, setLastUpdate] = React.useState(new Date());

  const TRACKING_ID = "G-N2HE08KZL8"; // OUR_TRACKING_ID (FROM CDOT)
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    setEs(new EventSource(process.env.REACT_APP_API_HOST  + process.env.REACT_APP_PUB_DIRECTORY))
  },[])

  const switchTheme = () => {
    setIsDark(!isDark)
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDark ? 'dark' : 'light',
        },
      }),
    [isDark],
  );

  if(es) {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
      <DataCacheProvider es={es} setLastUpdate={setLastUpdate}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout isDark={isDark} switchTheme={switchTheme} lastUpdate={lastUpdate}/>
        </ThemeProvider>
      </DataCacheProvider>
      </LocalizationProvider>
    );
  } else return null;
}

export default App;
