import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Location, Updated } from "../util/FormattingUtils";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ObjectCard = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const module = props.module;
    const obj = props.obj;
    const MyAvatar = module.avatar;
    const MyCardContent = module.cardContent;
    const MyTitle = module.cardTitle;
    const showCardContent = module.showCardContent;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card variant="outlined">
            <CardHeader
                avatar={
                    undefined != MyAvatar ?
                    <MyAvatar value={obj} module={module} /> : null
                }
                action={
                    undefined != MyCardContent && showCardContent && showCardContent(obj) ?
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore> : null
                }
                subheader={undefined != MyTitle ? <MyTitle obj={obj} /> : null}
            />
            {undefined != MyCardContent && showCardContent && showCardContent(obj) ?
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <MyCardContent obj={obj} {...props} />
                    </CardContent>
                </Collapse> : null
            }
        </Card>
    )
}

export {
    ObjectCard
}