import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import LayersIcon from '@mui/icons-material/Layers';
import LayerButton from './ImageButton'
import { styled } from '@mui/material/styles';
import { alpha } from "@mui/material";
import { publishLayer } from "../util/ArrayUtil";
import { blueGrey } from '@mui/material/colors'

const ControlBox = styled('div')(({ theme }) => ({
    display: 'flex', flexWrap: 'wrap', width: '100%', borderRadius: '5px', border: '10px', backgroundColor: alpha(blueGrey[900], 0.5), paddingRight: "2px", paddingLeft: "2px", boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px"
}));


const shouldPublishLayer = (module, showWms, domains) => {
    if (module.showOnMap) {
        if (module.wmsMapLayer && showWms && publishLayer(module.wmsPublish, domains)) return true;
        else return publishLayer(module.publishLayer, domains);
    }
    return false;
}

const LayersControl = (props) => {
    const modules = props.modules;
    const showWMS = props.showWMS;
    const domains = props.domains;
    return (
        <>
            <Box sx={{ mr: '10px', display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                <ControlBox>
                    {modules.map(module => {
                        if (shouldPublishLayer(module, showWMS, domains)) {
                            return (
                                <LayerButton {...props} module={module} key={module.name} />
                            )
                        }
                    })}
                </ControlBox>
            </Box>
        </>
    )
}

const LayersButton = (props) => {
    const setLayerButtonOpen = props.setLayerButtonOpen;

    const handleClick = () => {
        setLayerButtonOpen(layerButtonOpen => { return !layerButtonOpen });
    }

    return (
        <Box sx={{ width: "100%", textAlign: "right"}}>
            <IconButton aria-label="map-layers" size="large" variant="contained" onClick={handleClick} sx={{ paddingBottom: "0px"}}>
                <LayersIcon sx={{
                    fontSize: "36px", backgroundColor: "white", color: "rgba(0,0,0,0.6)", boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px", '&:hover': {
                        color: "rgba(0,0,0,1)"
                    },
                }} />
            </IconButton>
        </Box>
    )
}

export {
    LayersControl,
    LayersButton
}