import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import SideDrawerSection from "./SideDrawerSection"
import SearchBox from './SearchBox';

const drawerWidth = 350;

const Sections = (props) => {
  const modules = props.modules;
  return (
    <Box>
      <SearchBox {...props}/>
      {modules.map(module => {
        if (module.showOnSidebar) {
          return (
            <SideDrawerSection {...props} module={module} key={module.name} />
          )
        }
      })
      }
    </Box>
  )
}

export default function SideDrawer(props) {
  const { window } = props;
  const mobileOpen = props.mobileOpen;
  const handleDrawerToggle = props.handleDrawerToggle;
  const container = window !== undefined ? () => window().document.body : undefined;
  const isDark = props.isDark;

  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="right"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm:"block", md: 'none' },
          flexShrink: 0,
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "85%", maxWidth: drawerWidth},
        }}
      >
        <Box sx={{ overflow: "auto" }}>
          <Sections {...props} />
        </Box>

      </Drawer>
      <Drawer
        variant="persistent"
        anchor="right"
        open={mobileOpen}
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          flexShrink: 0,
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <Box sx={{ overflow: "auto", marginTop: "87px", marginBottom: "60px" }}>
          <Sections {...props} />
        </Box>
      </Drawer>
    </>

  )
}