import React, { useState } from 'react'

const DataCacheContext = React.createContext({})

export const DataCacheProvider = (props) => {
    const [dataCache, setDataCache] = useState({});
    const es = props.es;
    const setLastUpdate = props.setLastUpdate;

    const startWorkerUpdater = (endpoint, updateDelay) => {
        let updateWorker = new Worker('/updateWorker.js');
        updateWorker.postMessage({ msg: "connect", apiUrl: process.env.REACT_APP_API_HOST + "/" + endpoint, type: endpoint, updateDelay: updateDelay });
        es.addEventListener(endpoint, (e) => {
            updateWorker.postMessage({msg:"update", data:e.data});
            setLastUpdate(lastUpdate => {
                let now = new Date();
                if(now.getTime() - lastUpdate.getTime() > 60000) {
                    return now;
                } else {
                    return lastUpdate;
                }
            });
        })
        return updateWorker;
    }

    return (
        <DataCacheContext.Provider
            value={{
                es: es,
                data: dataCache,
                createDataCache: (endpoint, updateDelay) => {
                    if (dataCache[endpoint]) return dataCache[endpoint];
                    else {
                        setDataCache(dataCache => {
                            let newDataCache = { ...dataCache };
                            newDataCache[endpoint] = startWorkerUpdater(endpoint, updateDelay);
                            return newDataCache;
                        });
                    }
                }
            }}>{props.children}
        </DataCacheContext.Provider>
    )

}
export const DataCacheConsumer = DataCacheContext.Consumer;

export default DataCacheContext