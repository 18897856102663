import * as React from 'react';
import MyMarker from '../../components/Marker';
import { ObjectCard } from '../../components/ObjectCard';
import { Box } from '@mui/system';
import { titleCase } from 'title-case';

const endpoint = "assetsignals";

const showCardContent = (obj) => { return true }

const ModuleMarker = (props) => {
    return (
        <MyMarker {...props} icondef={getIcon()} />
    )
}

const getIcon = () => {
    return {
        url: "/mapicons/signal/signal.png",
        anchor: new window.google.maps.Point(6, 15),
        scaledSize: new window.google.maps.Size(11, 27)
    };
}

const CardContent = (props) => {
    const obj = props.obj;
    return (
        <>
            <Location value={obj} />
            <AbsoluteAddress value={obj}/>
        </>
    )
}

const AbsoluteAddress = props => {
    const obj = props.value;
    var absAddress = "";
    if(obj.nsAddress) {
        absAddress += obj.nsAddress;
    }
    if(obj.ewAddress) {
        if(absAddress !== "") {
            absAddress += " / "
        }
        absAddress += obj.ewAddress;
    }
    return (
        <Box>{absAddress}</Box>
    )
}

const Location = props => {
    const obj = props.value;
    if(obj.name) {
        return (
            <Box sx={{ fontWeight: 'bold'}}>{titleCase(obj.name.toLowerCase())}</Box>
        )
    } else {
        return null;
    }

}

const InfoWindow = (props) => {
    const module = props.module;
    const obj = props.icon;
    const MyCardContent = module.cardContent;
    const showCardContent = module.showCardContent;

    return (
        <div className="infoWindow center"><div>Traffic Signal</div>
            {showCardContent(obj) ?
                <div className="spacer"><MyCardContent obj={obj} /></div>
                :
                null}
        </div>
    )
}

export default {
    name: 'Signals',
    label: 'Traffic Signal #',
    marker: ModuleMarker,
    showOnMap: true, //show on map layer filter
    mapLayerIcon: "/mapicons/signal/signal.png",
    showOnSidebar: false,
    sideBarCard: ObjectCard,
    endpoint: endpoint,
    defaultOn: false,
    updateDelay: 0,
    showCardContent: showCardContent,
    cardContent: CardContent,
    infoWindow: InfoWindow,
    cluster: true,
    clusterMarker: "/mapicons/signal/signal",
    clusterClass: "signal-cluster",
    publishLayer: "tasWebapp.signals.enable"
}