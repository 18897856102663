
import { RoadworkMarker } from "./assets/Marker"
import { RoadworkInfoWindow } from "./assets/InfoWindow"
import { ObjectCard } from "../../components/ObjectCard"
import { EventAvatar, eventIcon } from "../../util/EventIcons"
import { Location } from "../../util/FormattingUtils"

const endpoint = "roadwork"

const showCardContent = (obj) => {return (obj.advisoryText && obj.advisoryText !== "")}

const EventCardContent = (props) => {
    const obj = props.obj;
    return (
        <div>{obj.advisoryText}</div>
    )
}

const EventCardTitle = (props) => {
    const obj = props.obj;
    return (
        <Location obj={obj} />
    )
}

const subsort = (objects) => {
    const objectsHash = {};
    for(let i = 0; i < objects.length; i++) {
        let event = objects[i];
        let impactLevel = event.impact + " Impact";
        if(!objectsHash[impactLevel]) {
            objectsHash[impactLevel] = [];
        }
        objectsHash[impactLevel].push(event);
    } 
    return objectsHash;
 }

export default {
    name: 'Roadwork',
    label: 'Roadwork #',
    marker: RoadworkMarker,
    showOnMap: true,
    wmsMapLayer: "cdot:roadworkoverlays",
    mapLayerIcon: "/mapicons/event/Roadwork_highclosed.png",
    sideBarCard: ObjectCard,
    showOnSidebar: true,
    infoWindow: RoadworkInfoWindow,
    endpoint: endpoint,
    defaultOn: false,
    updateDelay: 0,
    avatar: EventAvatar,
    getIcon: eventIcon,
    showCardContent: showCardContent,
    cardContent: EventCardContent,
    cardTitle: EventCardTitle,
    subSort: subsort,
    subSortOrder: ["Closed Impact", "High Impact", "Medium Impact", "Low Impact", "NoImpact Impact"],
    wmsPublish: "tasWebapp.eventpolygons.enable",
    publishLayer: "tasWebapp.eventicons.enable",
    resizeOnZoom: true
}