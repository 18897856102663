import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { Location, Updated } from "../../util/FormattingUtils";
import MyMarker from "../../components/Marker"
import { ObjectCard } from '../../components/ObjectCard';
import Box from "@mui/material/Box";
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const endpoint = "traveltimes"

const getIconUrl = (icon, endpoint) => {
    var iconname = endpoint + "_" + icon.dir.toLowerCase() + ".png";
    iconname = "/mapicons/" + endpoint + "/" + iconname;
    return iconname;
}

const getIcon = (icon, endpoint) => {
    return {
        url: getIconUrl(icon, endpoint),
        anchor: new window.google.maps.Point(0, 0),
        scaledSize: new window.google.maps.Size(35, 29)
    };
}

const TTMarker = (props) => {
    const endpoint = props.module.endpoint;
    const icondef = getIcon(props.icon, endpoint);

    return (
        <MyMarker {...props} icondef={icondef} />
    )
}

const getSize = (tt) => {
    if(tt === "n\a") return "-sm";
    if (tt <= 9) return "-sm"
    return "";
}

const TTAvatar = React.forwardRef(function MyComponent(props, ref) {
    const d = props.value;
    const module = props.module;
    let imageUrl = getIconUrl(d, module.endpoint);
    let imageName = "Travel Times";
    return (
        <Avatar alt={imageName} {...props} ref={ref} variant="square" sx={{ height: '45px', width: '45px', objectFit: "contain", bgcolor: "background.default" }} title={module.label  + d.displayId}>
            <Box sx={{
                backgroundImage: "url(" + imageUrl + ");",
                width: "100%",
                height: "100%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                display: "relative"
            }}
            ><div className={"tt-icon-text tt-icon-text-" + d.dir.toLowerCase() + getSize(d.traveltime)}>{"n/a" === d.traveltime?"--":d.traveltime}</div>
            </Box>
        </Avatar>
    )
});


const TTInfoWindow = (props) => {
    const module = props.module;
    const obj = props.icon;

    return (
        <div className="infoWindow">
            <div>{obj.name}</div>
            <hr>
            </hr><Updated icon={obj} /></div>
    )
}

const showCardContent = (obj) => { return false; }

const TTCardContent = (props) => {
    const obj = props.obj;
    return (
        <div>{obj.name}</div>
    )
}

const TTCardTitle = (props) => {
    const obj = props.obj;
    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ flexGrow: 0, paddingRight: "20px"}}>
                {obj.name}
            </Box>
            <Box sx={{ flexGrow: 1, marginLeft: "auto", textAlign: "right" }}>
                {
                    obj.trend === "DOWN" &&
                    <FontAwesomeIcon icon={faArrowUp} border className="tt-trend-up" size="lg" />
                }
                {
                    obj.trend === "UP" &&
                    <FontAwesomeIcon icon={faArrowDown} border className="tt-trend-down" size="lg" />
                }
            </Box>
        </Box>
    )
}

const subsort = (objects) => {
    const objectsHash = {};
    for(let i = 0; i < objects.length; i++) {
        let obj = objects[i];
        let name = obj.groupName;
        if(!name) name = "";
        if(!objectsHash[name]) {
            objectsHash[name] = [];
        }
        objectsHash[name].push(obj);
    } 
    return objectsHash;
 }

export default {
    name: 'Travel Times',
    label: 'Travel Time ',
    marker: TTMarker,
    showOnMap: false,
    mapLayerIcon: "/mapicons/traveltimes/traveltimes.png",
    sideBarCard: ObjectCard,
    showOnSidebar: true,
    infoWindow: TTInfoWindow,
    endpoint: endpoint,
    defaultOn: true,
    updateDelay: 0,
    avatar: TTAvatar,
    showCardContent: showCardContent,
    cardContent: TTCardContent,
    cardTitle: TTCardTitle,
    subSort: subsort
}