import * as React from 'react';
import styles from './DMS.module.css';

export const DmsDisplay = (props) => {
    const dms = props.dms;
    const message = dms.cmsMessage;
    const [phase, setPhase] = React.useState();
    const [phaseNum, setPhaseNum] = React.useState(0);
    const [timer, setTimer] = React.useState(null);

    const updatePhaseTimer = (phase) => {
        if(message.phases.length > 1) {
            const millisecs = phase.phaseTime ? phase.phaseTime * 1000 : 1000;
            return setTimeout(() => {
                setPhaseNum(phaseNum => { return ++phaseNum })
            }, millisecs);
        }
        return null;
    }

    React.useEffect(() => {
        setTimer(updatePhaseTimer(phase));
    }, [])

    React.useEffect(() => {
        if(message.phases.length > phaseNum) {
            setPhase(message.phases[phaseNum]);
        } else {
            setPhaseNum(0);
        }
    }, [phaseNum])

    React.useEffect(() => {
        setTimer(timer => {
            clearTimeout(timer);
            return updatePhaseTimer(phase)
        });
    }, [phase])

    React.useEffect(() => {
        if(message) {
            setPhase(message?.phases[0]);
        } 
    },[message])

    const pageCns = getClassNamesForPages(message);

    if(null !== phase) {
        return (
            <DmsPhase phase={phase} pageCns={pageCns} phaseNum={phaseNum} />
        )
    }
    return null;

}

const pageTagsRegex = /\[*.p(.*?)\]/g;

const DmsPhase = (props) => {
    const phase = props.phase;
    const cns = getClassNamesForLines(phase);
    const pageCns = props.pageCns;
    const phaseNum = props.phaseNum;
    return (
        <div className={getClassNames(pageCns, phaseNum, "dmsPhase")}>
            {phase?.lines.map((line, idx) => {
                return <DmsLine line={line} key={idx} lineNum={idx} cns={cns}/>
            })}
        </div>
    )
}

const DmsLine = (props) => {
    const line = props.line;
    const cns = props.cns;
    const lineNum = props.lineNum;
    const text = line.replace(bracketsRegex, "");
    return (
        <div className={getClassNames(cns, lineNum, "dmsLine")}>{text}</div>
    )
}


const lineTagsRegex = /\[*.l(.*?)\]/g;

const bracketsRegex = /\[(.*?)\]/g;

const removeBrackets = /[\[\]']+/g;

const getClassNamesForPages = (message) => {
    const classNames = [];
    message?.phases.forEach(phase => {
        classNames.push(convertTagsToClassNames(getTags(phase.markedUpText, pageTagsRegex)));
    })
    return classNames;
}

const getClassNamesForLines = (phase) => {
    const classNames = [];
    phase?.lines.forEach(line => {
        classNames.push(convertTagsToClassNames(getTags(line, lineTagsRegex)));
    })
    return classNames;
}

const convertTagsToClassNames = (tags) => {
    const cns = [];
    tags.forEach(tag => {
        const cn = tag.replace(removeBrackets, "");
        if(styles[cn]) {
            cns.push(styles[cn])
        } 
    })
    return cns;
}

const getClassNames = (cns, idx, defaultCn) => {
    var classNames = [styles[defaultCn]];
    console.log(cns);
    while(idx > -1) {
        if(cns[idx] && cns[idx].length > 0) {
            classNames = classNames.concat(cns[idx]);
            break;
        } else {
            --idx;
        }
    }
    return classNames.join(" ");
}

const getTags = (multi, regex) => {
    var tags = [];
    var found;
    while (found = regex.exec(multi)) {
        tags.push(found[0]);
    }
    return tags;
}