import * as React from 'react';
import MyMarker from '../../components/Marker';
import { ObjectCard } from '../../components/ObjectCard';
import Avatar from '@mui/material/Avatar';
import { Location, Updated } from "../../util/FormattingUtils"
import { DmsDisplay } from './DmsDisplay';

const endpoint = "dms";

const DMSMarker = (props) => {
    const currentZoom = props.currentZoom;
    const icondef = getIcon(props.icon, currentZoom);
    return (
        <MyMarker {...props} icondef={icondef} />
    )
}

const DmsAvatar = React.forwardRef(function MyComponent(props, ref) {
    const d = props.value;
    const module = props.module;
    let imageUrl = getDmsIconUrl(d);
    let imageName = d.signStatus + " " +d.messageStatus;
    if(d.portable) {
        return <Avatar src={imageUrl} alt={imageName} {...props} ref={ref} variant="square" sx={{ height: '26px', width: '26px', objectFit:"contain" }} title={module.label  + d.displayId}/>
    } else {
        return <Avatar src={imageUrl} alt={imageName} {...props} ref={ref} variant="square" sx={{ height: '36px', width: '36px', objectFit:"contain" }} title={module.label  + d.displayId}/>
    }
});


const getDmsIconUrl = (icon) => {
    var iconname;
    if (!icon.portable) {
        iconname = "ActiveBlank.png";
    } else {
        iconname = "Portable.png";
    }
    iconname = "/mapicons/dms/" + iconname;
    return iconname;
}

const getIcon = (icon, currentZoom) => {
    var scaledSize, anchor;
    if(!icon.portable) {
        if(currentZoom > 10) {
            scaledSize = new window.google.maps.Size(36, 36);
            anchor = new window.google.maps.Point(18, 5)
        } else {
            scaledSize = new window.google.maps.Size(24, 24);
            anchor = new window.google.maps.Point(12, 5)
        }
    } else {
        if(currentZoom > 10) {
            scaledSize = new window.google.maps.Size(26, 26)
            anchor = new window.google.maps.Point(14, 5);
        } else {
            scaledSize = new window.google.maps.Size(17, 17)
            anchor = new window.google.maps.Point(8, 5);
        }
    }

    return {
        url: getDmsIconUrl(icon),
        anchor: anchor,
        scaledSize: scaledSize
    };
}

const DmsCardTitle = (props) => {
    const obj = props.obj;
    return (
        <Location obj={obj} />
    )
}

const DmsCardContent = (props) => {
    const obj = props.obj;
    return (
        <DmsDisplay dms={obj}/>
    )
}

const DmsInfoWindow = (props) => {
    const module = props.module;
    const obj = props.icon;
    const MyCardContent = module.cardContent;
    const showCardContent = module.showCardContent;

    return (
        <div className="infoWindow"><Location obj={obj}/>
        {showCardContent(obj)?
            <div className="spacer"><MyCardContent obj={obj}/></div>
            :
            null}
        <hr>
        </hr><Updated icon={obj} /></div>
    )
}

const showCardContent = (obj) => {return true}

export default {
    name: 'Message Signs',
    label: 'Sign #',
    marker: DMSMarker,
    showOnMap: true, //show on map layer filter
    mapLayerIcon: "/mapicons/dms/dms.png",
    showOnSidebar: true,
    sideBarCard: ObjectCard,
    endpoint: endpoint,
    defaultOn: false,
    updateDelay: 0,
    avatar: DmsAvatar,
    cardTitle: DmsCardTitle,
    showCardContent: showCardContent,
    cardContent: DmsCardContent,
    infoWindow: DmsInfoWindow,
    publishLayer: "tasWebapp.cms.enable",
    resizeOnZoom: true
}