import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const a11yProps = (index) => {
    return {
        id: `info-tab-${index}`,
        'aria-controls': `info-tabpanel-${index}`,
    };
}



export default function AboutDialog(props) {
    const open = props.open;
    const setOpen = props.setOpen;
    const [value, setValue] = React.useState(0);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth={"lg"}
                fullScreen
            >
                <BootstrapDialogTitle id="info-dialog-title" onClose={handleClose}>
                    About the Chicago Traffic Tracker
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Tabs value={value} onChange={handleChange} aria-label="Chicago Traffic Tracker Tabs">
                        <Tab label="Basic Information" {...a11yProps(0)} />
                        <Tab label="Map Layers" {...a11yProps(1)} />
                        <Tab label="Disclaimer" {...a11yProps(2)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Typography variant="h6" gutterBottom component="div">About the Site</Typography>
                        <Typography variant="body1" component="div" gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }}>
                            <Typography variant="body1" component="div" paragraph>
                                The Chicago Traffic Tracker website is published by the Chicago Department of Transportation (CDOT). The website provides real-time traffic conditions on arterial streets, Average Daily Traffic (ADT) volumes, traffic signal locations, automated red-light enforcement camera intersections, automated speed enforcement camera locations, dynamic messaging sign locations, and at-grade rail crossings that affect travel times to/from Midway Airport.  The website continues to be developed.  CDOT anticipates adding and enhancing the Travel Tracker website with features such detour information, route-level alerts, live images and/or video from traffic cameras, truck routes, planned and un-planned roadway incidents and much more.  </Typography>
                        </Typography>
                        <Typography variant="h6" component="div" gutterBottom>Navigation Using the Map</Typography>
                        <Typography gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }} component="div">
                            <Typography variant="body1" component="div" paragraph>
                                The website uses the Google Maps API to display locations for devices and traffic events on the map.  The map can be panned and zoomed by clicking the appropriate control buttons on the top right corner of the map. The +/- buttons and the mouse scroll wheel also allow a user to zoom in and out. To move the map, hold the left mouse button and drag the map in any direction. Double-clicking anywhere on the map will zoom the map in by one level.
                            </Typography>
                            <Typography variant="body1" component="div" paragraph>
                                Users can toggle between a map view and satellite view by clicking the appropriate button at the bottom center of the map.
                            </Typography>
                            <Typography variant="body1" component="div" paragraph>
                                The map layers can be turned on and off individually by clicking their layer control in the layer popup. Access the layer popup by clicking on the stacked squares icon in the bottom right corner. Turning on multiple layers simultaneously may affect the performance of the site.
                            </Typography>
                            <Typography variant="body1" component="div" paragraph>
                                There is no need to refresh the page to view updates to the real-time traffic, layers automatically update when turned on.
                            </Typography>
                        </Typography>
                        <Typography variant="h6" gutterBottom>Viewing All Markers</Typography>
                        <Typography variant="body1" component="div" sx={{ borderTop: "1px solid silver", pt: 2 }} gutterBottom>
                            <Typography variant="body1" component="div" paragraph>
                                Some markers and other map features are not visible when map is zoomed out. As the map is zoomed in, more information will become visible. Limting the information visible at low zoom levels helps maintain full functionality and reduces lag on the site.
                            </Typography>
                        </Typography>
                        <Typography variant="h6" gutterBottom >Viewing Information About a Device or Traffic Event</Typography>
                        <Typography variant="body1" component="div" gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }}>
                            <Typography variant="body1" component="div" paragraph>
                                Each marker on the map, when displayed, represents the location of the device or traffic event. When the mouse pointer is placed on top of the marker, a simple description of the device or traffic event is displayed. Clicking on a marker will open a popup window which shows additional information on the selected item. Clicking on the “x” button in the popup window closes the pop-up window.
                            </Typography>
                        </Typography>
                        <Typography variant="h6" gutterBottom>Zooming to a Specific Location</Typography>
                        <Typography variant="body1" component="div" sx={{ borderTop: "1px solid silver", pt: 2 }} gutterBottom>
                            <Typography variant="body1" component="div" paragraph>
                                The search bar at the top left of the map allows you to navigate directly to an address, intersection, or landmark. Enter in the street or intersection of interest and click on the auto-complete option. The map will shift its focus and zoom-in to the selected location.
                            </Typography>
                        </Typography>
                        <Typography variant="h6" gutterBottom>System Requirements</Typography>
                        <Typography variant="body1" component="div" sx={{ borderTop: "1px solid silver", pt: 2 }} gutterBottom>
                            <Typography variant="body1" component="div" paragraph>
                                This site is best viewed using Mozilla Firefox or Google Chrome web browser on a computer or mobile device.
                            </Typography>
                        </Typography>
                        <Typography variant="h6" gutterBottom>Contact Us</Typography>
                        <Typography variant="body1" component="div" sx={{ borderTop: "1px solid silver", pt: 2 }} gutterBottom>
                            For additional information contact the CDOT Division of Traffic Safety:
                            2 N LaSalle St, Suite 950
                            Chicago, IL 60602.&nbsp;
                            <Link href="tel:312-744-7650">312-744-7650</Link><br />
                            Feedback and comments may be addressed to: &nbsp;
                            <Link href="mailto:TrafficTracker@cityofchicago.org">TrafficTracker@cityofchicago.org</Link>
                        </Typography>
                    </TabPanel>

                    <TabPanel value={value} index={1}>

                        <Typography variant="h6" gutterBottom>Real-time Arterial Traffic</Typography>
                        <Typography gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }} component="div">
                            <Typography variant="body1" component="div" paragraph>
                                Arterial congestion levels are estimated using various data sources, including real-time GPS probes from third party data providers, transit vehicles operated by the Chicago Transit Authority (CTA), and data collected by speed enforcement cameras.  CDOT currently monitors congestion levels on about 1,200 miles of principal arterials streets. Speed is estimated in half mile segments for each direction of travel. There are about 7,800 segments representing the 1200 miles of roadways. A reliable number of GPS traces are required for estimating speed for a segment. As a result, during off-peak hours (mid-day, nights, and weekends) speed status may not be shown on the map for many of the monitored segments. The segment speed color code on the map is based on the speed limit of the street segment. On most of the city streets the speed limit is 30 mph.
                            </Typography>
                            <Typography variant="button" gutterBottom>Segment Speed Colors</Typography>
                            <Typography sx={matches ? { width: "25%", ml: 1, mb: 2 } : { width: "100%" }} gutterBottom>
                                <Typography class="color-bar no-congestion-seg">No Congestion</Typography>
                                <Typography class="color-bar light-congestion-seg">Light Congestion</Typography>
                                <Typography class="color-bar med-congestion-seg">Medium Congestion</Typography>
                                <Typography class="color-bar hvy-congestion-seg">Heavy Congestion</Typography>
                                <Typography class="color-bar vhvy-congestion-seg">Very Heavy Congestion</Typography>
                                <Typography class="color-bar no-data">No Current Data</Typography>
                            </Typography>

                            <Typography variant="body1" component="div" paragraph>
                                The segment speed color code seen on the map is based on the real-time travel speeds and the posted speed limit of the street segment. On most city arterial streets, with few exceptions, the speed is 30 mph. Consequently, the congestion map shows green for speed above 20 mph, yellow/orange for 10 to 20 mph and red for speed below 10 mph.
                            </Typography>
                        </Typography>

                        <Typography variant="h6" gutterBottom>Congestion Outlook</Typography>
                        <Typography gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }} component="div">
                            <Typography variant="body1" component="div" paragraph>
                                The Congestion Outlook layer shows generalized traffic conditions of 29 distinct traffic zones throughout the city. Congestion Outlook shows the average of the last 4 months of historical traffic conditions for the time of the day and the day of the week on which the request is made.
                            </Typography>
                            <Typography variant="body1" component="div" paragraph>
                                Segment speed and congestion estimates for zones are based on the speed data of probe vehicles and other detection devices over the last several minutes. As a result, the current speed and congestion value for a segment or zone is more probabilistic than actual. Occasionally, bad data or unexpected behavior of a probe vehicle can skew the segment speed to be higher or lower. However, most volatility in arterial speed comes from the very nature of the arterials themselves. Due to a myriad of factors – including but not limited to frequent intersections, traffic signals, transit movements, availability of alternative routes, crashes, short length of the segments, etc. – speed on arterial segments can fluctuate from heavily congested to no congestion and back within a few minutes. The segment speed and zonal congestion estimates together may give a better understanding of the actual traffic conditions. There is a relatively high likelihood of encountering slower speeds on segments in a congested traffic zone, even though the map may show free flow conditions for the segment at that moment. Conversely, the slow speed currently shown for a given segment may change in quickly if the generalized condition for the zone it is in shows free-flow or low congestion. Despite the volatility in arterial speed, this map can act as a good reference for comparing segments or routes within the same traffic zone. </Typography>
                        </Typography>

                        <Typography variant="h6" gutterBottom>Average Daily Traffic (ADT)</Typography>
                        <Typography gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }} component="div">
                            <Typography variant="body1" component="div" paragraph>
                                Average Daily Traffic refers to the average number of vehicles traveling on a particular street (segment) in a 24-hour period. ADT numbers are derived from vehicle counts recorded by vehicle detection cameras at signalized intersection and by speed enforcement cameras over the last seven days. The ADT numbers will cover more roadway segments and become more accurate as CDOT adds more traffic cameras to the network. ADT numbers appear on the map near the locations where the cameras are located. The map must be zoomed in to view the ADT numbers. The actual number of vehicles passing through any particular location will of course be different on each day, depending on the day of the week, time of the year, weather, and other conditions. ADT numbers are typically shown for each direction of travel on bidirectional streets and are for the segment of road, within which they appear.  </Typography>
                        </Typography>
                        <Typography variant="h6" gutterBottom>Traffic Signals</Typography>
                        <Typography gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }} component="div">
                            <Typography variant="body1" component="div" paragraph>
                                Chicago has about 3,000 traffic signals throughout the city. About 500 of these signals are interconnected through a cellular/fiber-network that facilitates the centralized monitoring and control of signal operations. Whether interconnected or not, signals are programmed to synchronize with adjacent and nearby signals to improve the efficiency of vehicle travel. New signals are added and old signals modernized every year depending on need and availability of funds. CDOT is responsible for the physical design, construction, and timing of signals. CDOT’s Division of Traffic Safety and Technology is responsible for maintaining signal coordination and timing and the Division of Electrical Operations is responsible for the maintenance of the signals.  Residents may submit questions or concerns about traffic signal operations by calling 311 or by submitting an online service request. </Typography>
                        </Typography>
                        <Typography variant="h6" gutterBottom>Red Light Cameras</Typography>
                        <Typography gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }} component="div">
                            <Typography variant="body1" component="div" paragraph>
                                The purpose of the red light camera program at CDOT is safety. The program is designed to augment police enforcement efforts, as well as educational and encouragement programs aimed to ensuring and increasing the safety of our roads and public rights-of-way.  In 2003, Chicago launched the red light camera pilot program to address an increase in fatal motor vehicle crashes at and near signalized intersections. At the intersections equipped with cameras, the city saw an average reduction in red light running-related crashes of 24 percent. Currently, Chicago has 149 intersections with red light cameras.  The most recent crash statistics show that crashes of all types were down at intersections with red light cameras when compared to city-wide crashes. The fine for running a red light is $100. For more information on a red-light program see the Red Light Camera Enforcement <a href="https://www.chicago.gov/city/en/depts/cdot/supp_info/red-light_cameraenforcement.html">webpage</a>. To pay a ticket or to view your violations visit the Department of Revenue's <a href="https://www.chicago.gov/city/en/depts/fin/provdrs/parking_and_redlightcitationadministration/svcs/pay_parking_and_red-lightticketson-line.html">website</a>.</Typography>
                        </Typography>
                        <Typography variant="h6" gutterBottom>Speed Cameras</Typography>
                        <Typography gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }} component="div">
                            <Typography variant="body1" component="div" paragraph>
                                In August 2013, Chicago launched the pilot for the Children’s Safety Zone Program, which deploys automated speed camera enforcement at school and park Safety Zones. There are currently 162 speed enforcement cameras. In addition to enforcement operation, the cameras are continuously recording speed and counting each vehicle passing by the camerax. Additional information on this program can be found <a href="https://www.chicago.gov/city/en/depts/cdot/supp_info/children_s_safetyzoneporgramautomaticspeedenforcement.html">here</a>.
                            </Typography>
                        </Typography>

                        <Typography variant="h6" gutterBottom>Crossings</Typography>
                        <Typography gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }} component="div">
                            <Typography variant="body1" component="div" paragraph>
                                CDOT has placed sensing devices at two at-grade train crossing locations near Midway airport, in order to detect when the crossing gates are down/closed. This information on crossing gate status is used for estimating travel times to and from Midway airport.    </Typography>
                        </Typography>
                        <Typography variant="h6" gutterBottom>Message Signs</Typography>
                        <Typography gutterBottom sx={{ borderTop: "1px solid silver", pt: 2 }} component="div">
                            <Typography variant="body1" component="div" paragraph>
                                This layer displays the location and current messages of dynamic message signs (DMS) managed by CDOT. These signs give the motorists real-time traffic information on travel times, special events, street closures and road blockages etc. CDOT-managed signs are primarily on the arterial streets. Freeway signs are managed by IDOT and the Illinois Tollway.  In the future, this layer will also provide information on and data from portable message signs.
                            </Typography>
                        </Typography>
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <Typography variant="body1" component="div">
                            By using the Chicago Traffic Tracker website, you are accepting that any and all maps/data and information you may receive (the "data") "as is" and without any warranty or representation whatsoever, express or implied, whether for accuracy, timeliness, completeness, fitness for a particular purpose or otherwise. The information on this website is provided here for reference purpose only. While every effort is employed to provide as much accurate information as possible, City of Chicago, any of its departments, or any of its employees cannot be held liable for the accuracy and completeness of this information. Speed and congestion estimates are probabilistic and are not actual speed. Occasionally outlier data or unexpected behavior of a probe vehicle can skew the segment speed to be higher or lower than the ground truth.
                        </Typography>

                    </TabPanel>

                </DialogContent>


                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
