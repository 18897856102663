import Incidents from "./Incidents"
import Roadwork from "./Roadwork"
import TravelTimes from "./TravelTimes"
import Train from "./Train"
import Google from "./GoogleTraffic"
import Congestion from "./Congestion"
import ADT from "./ADT"
import Boundaries from "./CityBoundaries"
import DMS from "./DMS"
import Parking from "./Parking"
import RedLight from "./RedLight"
import SpeedCamera from "./SpeedCamera"
import CongestionRegions from "./CongestionRegions"
import Signal from './Signal'

const definedModules = [
    Incidents,
    Roadwork,
    TravelTimes,
    Train, 
    DMS,
    Parking, 
    RedLight,
    SpeedCamera,
    Signal,
    Congestion,
    CongestionRegions,
    ADT,
    Boundaries,
    Google, 
];

export default definedModules;