import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { StandaloneSearchBox } from '@react-google-maps/api';
import { latlng } from "../util/FormattingUtils";

export default function PlacesSearchBox(props) {
    const [searchbox, setSearchbox] = React.useState(null);
    const [marker, setMarker] = React.useState(null);
    const map = props.map;
    const inputRef = useRef(null);

    const onLoadSearchBox = (sb) => {
        let searchBoxOptions = {
            strictBounds: true,
            autocomplete: true,
            fields: ['geometry']
        }
        sb.setOptions(searchBoxOptions);
        map.addListener("bounds_changed", () => {
            sb.setBounds(map.getBounds());
        });
        setSearchbox(sb);
    }

    const onPlacesChanged = () => {
        var mySearchBox = searchbox;
        console.log(mySearchBox.getPlaces());
        var places = searchbox.getPlaces();
        if (!places || places.length < 1) {
            return;
        }
        var place = places[0];
        var latlng = new window.google.maps.LatLng(
            place.geometry.location.lat(),
            place.geometry.location.lng());
        map.panTo(latlng);
        map.setZoom(15);
        setMarker(new window.google.maps.Marker({
            position: latlng,
            map: map,
            draggable: false
        }));
    }

    React.useEffect(() => {
        if (marker) {
            setTimeout(() => {
                marker.setMap(null);
                inputRef.current.value = "";
            }, 6000)
        }
    }, [marker, inputRef])

    return (
        <StandaloneSearchBox
            onPlacesChanged={
                onPlacesChanged
            }
            onLoad={onLoadSearchBox}
        >
            <input
                ref={inputRef}
                type="text"
                placeholder="Find a Intersection or Address"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `300px`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: "5px",
                    top: "8px"
                }}
            />
        </StandaloneSearchBox>
    )

}