
const updateItem = (item, items) => {
    let data = [];
    if (item.removed) {
        const removedItem = item.proxy;
        data = items.filter((i) => {
            return i.id !== removedItem.id;
        })
    } else {
        const foundIdx = items.findIndex(i => i.id === item.id);
        if (-1 !== foundIdx) {
            data = [...items];
            data[foundIdx] = item;
        } else {
            data = [...items, item];
        }
    }
    return data;
}

const removeItem = (item, items) => {
    const id = item.id;
    if(id) {
        return items.filter((i) => {
            return i.id !== id;
        })
    }
    return items;
}

const getArrayDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.id === object2.id;
      });
    });
  }

const setDomain = (domains, key, func) => {
    if(null != domains) {
        domains.forEach(element => {
            if (element.name === key) {
                if(element.value === 'false' || element.value === 'true') {
                    func(element.value === 'true')
                } else {
                    func(element.value);
                }
            }
        });
    }
}

const publishLayer = (key, domains) => {
    if (domains && key) {
        let domain = domains.find(element => element.name === key);
        return domain?.value === "true";
    }
    return false;
}

export {
    updateItem, 
    removeItem,
    getArrayDifference,
    setDomain,
    publishLayer
}


