import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Marker } from "@react-google-maps/api";

export default function MyMarker(props) {
    const [marker, setMarker] = useState();
    const setSelectedMarker = props.setSelectedMarker;
    const icon = props.icon;
    const icondef = props.icondef;
    const pos = props.position;
    const module = props.module;
    const clusterer = props.clusterer;
    const currentZoom = props.currentZoom;
    const resizeOnZoom = module.resizeOnZoom;
    
    const markerClick = (event) => {
        setSelectedMarker({ icon: icon, module: module });
    }

    const onLoad = (mymarker) => {
        setMarker(mymarker);
    }

    useEffect(() => {
        if(resizeOnZoom && currentZoom < 11) {
            var blah = marker;
            var boo = blah;
        }
    },[currentZoom,resizeOnZoom,marker])

    if (clusterer) {
        return (
            <Marker
                key={icon.id}
                icon={icondef}
                position={pos}
                title={module.label + icon.displayId}
                onClick={markerClick}
                clusterer={clusterer}
                onLoad={onLoad}
                >
            </Marker>
        )
    }
    else {
        return <Marker
            key={icon.id}
            icon={icondef}
            position={pos}
            title={module.label + icon.displayId}
            onClick={markerClick}
            onLoad={onLoad}>
        </Marker>
    }
}