import { IncidentMarker } from "./assets/Marker"
import { IncidentInfoWindow } from "./assets/InfoWindow"
import { ObjectCard } from "../../components/ObjectCard"
import { EventAvatar } from "../../util/EventIcons"
import { Location } from "../../util/FormattingUtils"

const endpoint = "incident"

const showCardContent = (obj) => {return (obj.advisoryText && obj.advisoryText !== "")}

const EventCardContent = (props) => {
    const obj = props.obj;
    return (
        <div>{obj.advisoryText}</div>
    )
}

const EventCardTitle = (props) => {
    const obj = props.obj;
    return (
        <Location obj={obj} />
    )
}

const subsort = (objects) => {
   const objectsHash = {};
   for(let i = 0; i < objects.length; i++) {
       let event = objects[i];
       let impactLevel = event.impact + " Impact";
       if(!objectsHash[impactLevel]) {
           objectsHash[impactLevel] = [];
       }
       objectsHash[impactLevel].push(event);
   } 
   return objectsHash;
}

export default {
    name: 'Incidents',
    label: 'Incident #',
    marker: IncidentMarker,
    showOnMap: true,
    wmsMapLayer: "cdot:othereventoverlays,cdot:specialeventoverlays",
    mapLayerIcon: "/mapicons/event/Incident_highclosed.png",
    sideBarCard: ObjectCard,
    showOnSidebar: true,
    infoWindow: IncidentInfoWindow,
    endpoint: endpoint,
    defaultOn: false,
    updateDelay: 0,
    avatar: EventAvatar,
    showCardContent: showCardContent,
    cardContent: EventCardContent,
    cardTitle: EventCardTitle,
    subSort: subsort,
    subSortOrder: ["Closed Impact", "High Impact", "Medium Impact", "Low Impact", "NoImpact Impact"],
    wmsPublish: "tasWebapp.eventpolygons.enable",
    publishLayer: "tasWebapp.eventicons.enable",
    resizeOnZoom: true
}