
import React, { useState, useEffect, useContext } from 'react'
import Marquee from "react-fast-marquee";
import DomainContext from '../context/DomainContext';
import DataCacheContext from '../context/DataCacheContext';

export default function TasMarquee(props) {
    const [informational, setInformational] = React.useState(false);
    const [alerts, setAlerts] = React.useState([]);
    const [error, setError] = React.useState("");
    const [gradient, setGradient] = useState([139, 0, 0]);
    const [showDivider, setShowDivider] = React.useState(false);
    const marqueeTextRef = React.useRef();
    const marqueeRef = React.useRef();
    const showMarquee = props.showMarquee;
    const setMarqueeText = props.setMarqueeText;
    const marqueeText = props.marqueeText;
    const es = props.es;
    const domains = useContext(DomainContext);
    const dataCache = useContext(DataCacheContext);

    useEffect(() => {
        const fetchApi = async () => {
            const res = await fetch(process.env.REACT_APP_API_HOST + "/event/alerts");
            res.json()
                .then(res => {
                    setAlerts(res);
                })
        }
        fetchApi().catch(err => {
            setError(err)
        });
    }, []);

    /** this is not ideal **/
    useEffect(() => {
        if (!dataCache.data["incident"]) {
            dataCache.createDataCache("incident", 0);
        } else {
            dataCache.data["incident"].addEventListener('message', function (e) {
                setObjectsListener(e);
            })
            dataCache.data["incident"].postMessage({ msg: "resume" });
        }
    }, [dataCache]);

    const setObjectsListener = (e) => {
        const events = e.data;
        setAlerts(events.filter(event => event.scroller));
    }

    React.useEffect(() => {
        const text = alerts.map(alert => alert.advisoryText).filter(alert => alert.trim() !== "");
        const alert = alerts.find(alert => "Informational" !== alert.scrollerType && alert.advisoryText && alert.advisoryText.trim() !== "")
        setInformational(undefined === alert);
        setMarqueeText(text.join(" | "));
    }, [alerts])

    React.useEffect(() => {
        if (informational) {
            setGradient([21, 79, 117])
        } else {
            setGradient([139, 0, 0])
        }
    }, [
        informational
    ])

    React.useEffect(() => {
        if (marqueeTextRef && marqueeTextRef.current) {
            const textWidth = marqueeTextRef.current.offsetWidth;
            const marqueeWidth = marqueeRef.current.offsetWidth;
            setShowDivider(textWidth > marqueeWidth)
        }
    }, [marqueeText])

    if (showMarquee && marqueeText !== "") {
        return (
            <div className="marqueeWrapper" ref={marqueeRef}>
                <Marquee className={informational ? "marquee marquee-info" : "marquee marquee-warning"} gradient={true} speed={50} gradientColor={gradient} gradientWidth={50}>
                    {showDivider ? <div>&nbsp;|&nbsp;</div> : null}
                    <div ref={marqueeTextRef}>{marqueeText}</div>
                </Marquee>
            </div>
        )
    } else {
        return null;
    }
}