export default  {
    name: "City of Chicago Boundaries",
    mapLayerIcon: "",
    defaultOn: true,
    showOnMap: true,
    publishLayer: "tasWebapp.cityboundaries.enable",
    geoJsonUrl: "static/cityboundaries.json",
    geoOptions: {
        fillOpacity:0,
        strokeColor:"#6082B6",
        strokeWidth:2
    }
}
