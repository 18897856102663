import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';


export default function Header(props) {
  const handleDrawerToggle = props.handleDrawerToggle;
  const loggedIn = props.loggedIn;
  const client = process.env.REACT_APP_CLIENT;
  const isUser = props.isUser;

  let title = process.env.REACT_APP_TITLE;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  var url = "/cdot_logo.png";
  var bgcolor = "#005899";
  if(matches) {
    url = "/traffictracker.png";
  }

  const toolbar = (
    <>
      <Box sx={{ display: "flex", m:0,p:0, backgroundColor:bgcolor, minHeight:87, height:87, maxHeight:87}}>
        {matches && 
        <Box
          sx={{
            flexGrow: 3,
            width:"100%",
            minHeight: 87,
            maxHeight: 87,
            height: 87,
            backgroundImage: 'url(' + url + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left center',
          }}
          title={title}
        ></Box>}
        {!matches && <Box sx={{
            flexGrow: 3,
            width:"100%",
            minHeight: 60,
            height: 72,
            pt:1,
            backgroundColor:bgcolor,
            borderBottom: "#ffffff 3px dashed",
            pb: 2,
            ml: 1
          }} ><Typography variant="h6" sx={{color:"#a4d5ee"}}>{title.toUpperCase()}</Typography>
          <Typography variant="body1" sx={{color:"#fec240", textAlign:"left", ml:1, fontWeight:"bold"}}>Real Time Traffic for Chicago</Typography>
          </Box>}
        <Box sx={{
          flexGrow: 2,
          maxHeight:87,
          textAlign: "right",
        }}>
          <IconButton
            color="inherit"
            aria-label="open or close sidebar"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ verticalAlign: "middle", pr: 2, height:65, pt:2 }}
            title="Open or Close sidebar"
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>
    </>


  )

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: { xs: 'none', sm: 'none', md: 'flex' } }}>
        {toolbar}
      </AppBar>
      <AppBar position="fixed" sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
        {toolbar}
      </AppBar>
    </>
  )

}