import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Polygon, MarkerClusterer } from '@react-google-maps/api';
import DataCacheContext from '../context/DataCacheContext';
import LayersContext from '../context/LayersContext';
import SearchContext from '../context/SearchContext';
import { applyAllFilters } from '../util/SearchUtil';
import { getArrayDifference } from '../util/ArrayUtil';

const validLatLng = (icon) => {
  if (!icon.iconPoint) return false;
  return icon.iconPoint.latitude && icon.iconPoint.longitude && !isNaN(icon.iconPoint.latitude) && !isNaN(icon.iconPoint.longitude);
}

const latlng = (icon) => {
  return { lat: icon.iconPoint.latitude, lng: icon.iconPoint.longitude }
}

/*const getPixelPositionOffset = (offsetWidth, offsetHeight, icon) => {
  var labelAnchor = { x: 10, y: -52 };
  if (icon.rotation > 45 && icon.rotation <= 135) {
    labelAnchor = { x: -40, y: -52 };
  }
  return {
    x: offsetWidth + labelAnchor.x,
    y: offsetHeight + labelAnchor.y,
  };
};

const MapPolyline = (props) => {
  var icon = props.icon;
  const options = {
    strokeColor: icon.congestionColor,
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    paths: icon.polyline,
    zIndex: 1
  }
  return (
    <Polyline
      path={icon.polyline}
      options={options}
    />
  )
}*/

const MapPolygon = (props) => {
  var icon = props.icon;
  const options = {
    strokeColor: "#000000",
    strokeOpacity: 0.5,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    fillColor: icon.congestionColor,
    fillOpacity: 0.75,
  }
  return (
    <Polygon paths={icon.polygon} options={options} />
  )
}

const MapMarker = (props) => {
  const icon = props.icon;
  var pos = latlng(icon);
  const MyMarker = props.module.marker;
  return (
    <MyMarker {...props} position={pos} />
  )
}

export default function EndpointIconLayer(props) {
  const layersContext = React.useContext(LayersContext);
  const searchContext = useContext(SearchContext);
  const module = props.module;
  const title = module.name;
  const [allObjects, setAllObjects] = useState([]);
  const [objects, setObjects] = useState([]);
  const [visible, setVisible] = useState(module.defaultOn);
  const dataCache = useContext(DataCacheContext);
  const endpoint = module.endpoint;
  const updateDelay = module.updateDelay;
  const selectedMarker = props.selectedMarker;
  const setSelectedMarker = props.setSelectedMarker;
  const setLayersOn = props.setLayersOn;
  const setRefreshWms = props.setRefreshWms;
  const currentZoom = props.currentZoom;
  const resizeOnZoom = module.resizeOnZoom;
  const [lastZoom, setLastZoom] = React.useState(currentZoom);

  useEffect(() => {
    if (visible) {
      setLayersOn(layersContext => {
        let newLayersContext = { ...layersContext };
        newLayersContext[title] = true;
        return newLayersContext;
      })
    }
  }, [])

  useEffect(() => {
    if (!dataCache.data[endpoint]) {
      dataCache.createDataCache(endpoint, updateDelay);
    } else {
      dataCache.data[endpoint].addEventListener('message', function (e) {
        setObjectsListener(e);
      })
      dataCache.data[endpoint].postMessage({ msg: "resume" });
    }
  }, [endpoint, dataCache, updateDelay]);

  useEffect(() => {
    if (undefined !== layersContext[title]) {
      const isSelected = layersContext[title];
      setVisible(isSelected);
      if (!isSelected) {
        if (selectedMarker) {
          var found = objects.some(obj => selectedMarker === obj.id);
          if (found) {
            setSelectedMarker(null);
          }
        }
      }
    }
  }, [layersContext[title], title]);

  useEffect(() => {
    applyAllFilters(allObjects, searchContext.searchString, setObjects);
  }, [allObjects, searchContext.searchString])

  const setObjectsListener = (e) => {
    const removed = getArrayDifference(allObjects, e.data).filter(obj => obj.geometry);
    setRefreshWms(removed.length > 0);
    setAllObjects(e.data);
  }

  useEffect(() => {
    if(resizeOnZoom) {
      if((currentZoom === 7 && lastZoom === 8) || (currentZoom === 8 && lastZoom === 7)) {
        setObjects(objects => {return [...objects]}); 
      } else if ((currentZoom === 11 && lastZoom === 10) || (currentZoom === 10 && lastZoom === 11 )) {
        setObjects(objects => {return [...objects]});
      }
      setLastZoom(currentZoom);
    }
  },[currentZoom,resizeOnZoom,lastZoom])

  const endPointLayer = useMemo(() => <Layer objects={objects} {...props} module={module}/>, [objects]);


  if (objects.length === 0 || !visible) {
    return null;
  } else {
    return (
      <>
        {endPointLayer}
      </>
    )
  }
}

const Layer = (props) => {
  const objects = props.objects;
  const module = props.module;

  const markers = objects.map(obj => {
    if (validLatLng(obj)) return <MapMarker {...props} icon={obj} key={obj.id + "mapmarker"} />;
    else if(obj.polygon) return <MapPolygon icon={obj} key={obj.id + "mappolygon"}/>
    return null;
  })

  if(module.cluster) {
    const options = {
      imagePath:module.clusterMarker,
      imageExtension:"png",
      imageSizes:[30,35,40,45,50,55]
    }
    return (
      <MarkerClusterer options={options} clusterClass={module.clusterClass} maxZoom={13}>
        {(clusterer) => objects.map((obj) => (
          <MapMarker {...props} icon={obj} key={obj.id + "mapmarker"} clusterer={clusterer}/>
        ))
        }
      </MarkerClusterer>
    )
  } else {
    return (
      markers
    )
  }
}