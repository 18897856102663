import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import TwitterIcon from '@mui/icons-material/Twitter';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FacebookIcon from '@mui/icons-material/Facebook';
import InfoIcon from '@mui/icons-material/Info';
import AboutDialog from './About';

export default function Footer(props) {
    const [open, setOpen] = React.useState(false);
    const setFooterSelection = props.setFooterSelection;
    const footerSelection = props.footerSelection;

    return (
        <>
            <AboutDialog open={open} setOpen={setOpen}/>
            <BottomNavigation
                showLabels
                value={footerSelection}
                onChange={(event, newValue) => {
                    setFooterSelection(newValue);
                }}
                sx={{
                    display: { xs: 'none', sm: 'none', md:'flex' },
                    width: `100%`,
                    cursor:"pointer",
                }}
            >
                <BottomNavigationAction sx={{cursor:"pointer"}} label="Twitter" title="CDOT Twitter" icon={<TwitterIcon />} onClick={() => {window.location.href = "https://twitter.com/ChicagoDOT"}}/>
                <BottomNavigationAction sx={{cursor:"pointer"}} label="Facebook" title="CDOT Facebook" icon={<FacebookIcon />} onClick={() => {window.location.href = "https://www.facebook.com/CDOTNews/"}}/>
                <BottomNavigationAction sx={{cursor:"pointer"}} label="Location" title="Find Your Location" icon={<MyLocationIcon />} />
                <BottomNavigationAction sx={{cursor:"pointer"}} label="Chicago" title="City of Chicago" icon={<LocationCityIcon />} onClick={() => {window.location.href = "https://www.chicago.gov"}}/>
                <BottomNavigationAction sx={{cursor:"pointer"}} label="About" title="About the Chicago Traffic Tracker" icon={<InfoIcon />} onClick={() => {setOpen(true);setFooterSelection(null)}}/>
            </BottomNavigation>
            <BottomNavigation
                value={footerSelection}
                onChange={(event, newValue) => {
                    setFooterSelection(newValue);
                }}
                sx={{
                    display: { xs: 'flex', sm: 'flex', md:'none' },
                    width: `100%`,
                    cursor:"pointer",
                }}
            >
                <BottomNavigationAction sx={{cursor:"pointer"}} label="Twitter" title="CDOT Twitter" icon={<TwitterIcon />} onClick={() => {window.location.href = "https://twitter.com/ChicagoDOT"}}/>
                <BottomNavigationAction sx={{cursor:"pointer"}} label="Facebook" title="CDOT Facebook" icon={<FacebookIcon />} onClick={() => {window.location.href = "https://www.facebook.com/CDOTNews/"}}/>
                <BottomNavigationAction sx={{cursor:"pointer"}} label="Location" title="Find Your Location" icon={<MyLocationIcon />} />
                <BottomNavigationAction sx={{cursor:"pointer"}} label="Chicago" title="City of Chicago" icon={<LocationCityIcon />}  onClick={() => {window.location.href = "https://www.chicago.gov"}}/>
                <BottomNavigationAction sx={{cursor:"pointer"}} label="About" title="About the Chicago Traffic Tracker" icon={<InfoIcon />}  onClick={() => {setOpen(true);setFooterSelection(null)}}/>
            </BottomNavigation>
        </>
    )
}