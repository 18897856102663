import * as React from 'react';
import DataCacheContext from '../context/DataCacheContext';
import LayersContext from '../context/LayersContext';
import SearchContext from '../context/SearchContext';
import { applyAllFilters } from '../util/SearchUtil';

export default function SideDrawerSection(props) {
    const layersContext = React.useContext(LayersContext);
    const dataCache = React.useContext(DataCacheContext);
    const searchContext = React.useContext(SearchContext);
    const module = props.module;

    const [objects, setObjects] = React.useState([]);
    const [allObjects, setAllObjects] = React.useState([]);
    const [visible, setVisible] = React.useState(module.defaultOn);
    const [sortedObjects, setSortedObjects] = React.useState(undefined);
    const [order, setOrder] = React.useState(undefined);
    const endpoint = module.endpoint;
    const updateDelay = module.updateDelay;
    const SideBarCard = module.sideBarCard;
    const title = module.name;
    const subSort = module.subSort;
    const subSortOrder = module.subSortOrder;

    React.useEffect(() => {
        if (undefined !== subSort && objects && objects.length > 0) {
            let mySortedObjects = subSort(objects);
            setSortedObjects(mySortedObjects);
            let keys = Object.keys(mySortedObjects);
            setOrder(undefined !== subSortOrder?subSortOrder:keys);
        }
    }, [subSort, objects]);

    React.useEffect(() => {
        if (!dataCache.data[endpoint]) {
            dataCache.createDataCache(endpoint, updateDelay);
        } else {
            dataCache.data[endpoint].addEventListener('message', function (e) {
                setObjectsListener(e);
            })
            dataCache.data[endpoint].postMessage({ msg: "resume" });
        }
    }, [endpoint, dataCache]);

    React.useEffect(() => {
        applyAllFilters(allObjects, searchContext.searchString, setObjects);
    }, [allObjects, searchContext.searchString])

    const setObjectsListener = (e) => {
        setAllObjects(e.data);
    }

    React.useEffect(() => {
        if (undefined !== layersContext[title]) {
            setVisible(layersContext[title]);
        }
    }, [layersContext[title], title]);

    if (objects.length === 0 || !visible) {
        return null;
    } else {
        if (undefined === sortedObjects) {
            return (
                <div>
                    <div className="sidebar-section-title">{title}</div>
                    {objects.map(obj => {
                        return (<SideBarCard {...props} obj={obj} key={obj.id + "sidebarcard"} />)
                    })}
                </div>
            )
        } else {
            if(undefined != order) {
                return (
                    <div>
                        <div className="sidebar-section-title">{title}</div>
                        {order.map(key => {
                            let items = sortedObjects[key];
                            if(items && items.length > 0) {
                                return (
                                    <div key={key}>
                                        <div className="sidebar-section-subtitle">{key}</div>
                                        {items.map(obj => {
                                            return (<SideBarCard {...props} obj={obj} key={obj.id} />)
                                        })}
                                    </div>
                                );
                            } else return null;
                        }
                        )}
                    </div>
                )
            } else {
                return null;
            }

        }
    }
}