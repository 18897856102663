import { useState, useEffect,useContext } from 'react'
import { Data } from '@react-google-maps/api';
import LayersContext from '../context/LayersContext';

export default function GeoJsonLayer(props) {
    const layersContext = useContext(LayersContext);
    const module = props.module;
    const geoJsonUrl = module.geoJsonUrl;
    const setLayersOn = props.setLayersOn;
    const geoOptions = module.geoOptions;

    const title = module.name;
    const [visible, setVisible] = useState(module.defaultOn);
    const [dataLayer, setDataLayer] = useState(null);

    useEffect(() => {
        if (visible) {
            setLayersOn(layersContext => {
                let newLayersContext = { ...layersContext };
                newLayersContext[title] = true;
                return newLayersContext;
            })
        }
    }, [])

    useEffect(() => {
        if (undefined !== layersContext[title]) {
            const isSelected = layersContext[title];
            setVisible(isSelected);
        }
    }, [layersContext[title], title]);

    useEffect(() => {
        if(dataLayer) {
            let style = geoOptions;
            style.visible = visible;
            dataLayer.setStyle(style);
        }
    },[visible,dataLayer])

    const onLoad = data => {
        if(geoJsonUrl) {
            data.loadGeoJson(geoJsonUrl);
        }
        setDataLayer(data);
    }

    return (
        <Data onLoad={onLoad} />
    )

}