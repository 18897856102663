import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HelpIcon from '@mui/icons-material/Help';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from "react-router-dom";

const navLinks = ["/user","/user/profiles", "/user/vehicles"];

export default function UserFooter(props) {
    const setFooterSelection = props.setFooterSelection;
    const footerSelection = props.footerSelection;
    let navigate = useNavigate();

    return (
        <>
            <BottomNavigation
                position="fixed"
                showLabels
                value={footerSelection}
                onChange={(event, newValue) => {
                    setFooterSelection(newValue);
                    navigate(navLinks[newValue]);
                }}
                sx={{
                    display: { xs: 'none', sm: 'flex' },
                    width: `100%`,
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
            >
                <BottomNavigationAction label="Manage" icon={<ManageAccountsIcon />} />
                <BottomNavigationAction label="Profiles" icon={<EmailIcon />} />
                <BottomNavigationAction label="Vehicles" icon={<LocalShippingIcon />} />
            </BottomNavigation>
            <BottomNavigation
                value={footerSelection}
                onChange={(event, newValue) => {
                    setFooterSelection(newValue);
                    navigate(navLinks[newValue]);
                }}
                sx={{
                    display: { xs: 'flex', sm: 'none' },
                    width: `100%`
                }}
            >
                <BottomNavigationAction label="Manage" icon={<ManageAccountsIcon />} />
                <BottomNavigationAction label="Profiles" icon={<EmailIcon />} />
                <BottomNavigationAction label="Vehicles" icon={<LocalShippingIcon />} />
            </BottomNavigation>
        </>
    )
}