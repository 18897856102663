import * as React from 'react';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    border: "1px solid 	rgba(192,192,192,0.5)",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
    display:'flex'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('md')]: {
            width: '21ch',
        },
    },
}));

export default function SearchBox(props) {
    const [inputValue, setInputValue] = React.useState("");
    const setSearchFilters = props.setSearchFilters;

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            if (e.target.value === "") {
                setSearchFilters((search) => {
                    return { ...search, searchString: " " }
                });
            }
            else {
                setSearchFilters((search) => {
                    return { ...search, searchString: e.target.value }
                });
            }
        }
    }

    const onKeyUp = (e) => {
        if (e.key === "Backspace" && "" === e.target.value) {
            clearSearch(e);
        }
    }

    const onSearchChange = (e) => {
        setInputValue(e.target.value);
    }

    const clearSearch = (e) => {
        setSearchFilters((search) => {
            return { ...search, searchString: " " }
        });
        setInputValue("");
    }

    const activateSearch = (e) => {
        setSearchFilters((search) => {
            return { ...search, searchString: inputValue }
        });
    }

    return (
        <Box sx={{ margin: "10px" }}>
            <Search>
                <IconButton type="button" aria-label="clear" onClick={activateSearch}>
                    <SearchIcon />
                </IconButton>
                <StyledInputBase
                    placeholder="Search Map Features"
                    inputProps={{ 'aria-label': 'search', onChange: onSearchChange, onKeyDown: onKeyDown, onKeyUp: onKeyUp }}
                    value={inputValue}
                />
                <IconButton type="button" aria-label="clear" onClick={clearSearch} sx={{position: "absolute", right: 0}}>
                    <CloseIcon />
                </IconButton>
            </Search>
        </Box>
    )
}