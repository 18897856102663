import * as React from 'react';
import MyMarker from '../../components/Marker';
import { ObjectCard } from '../../components/ObjectCard';
import styles from './SpeedCamera.module.css';
import { MonitoredApproaches,CameraInstalled } from "../../util/FormattingUtils"
import { Box } from '@mui/system';

const endpoint = "speedenforcement";

const showCardContent = (obj) => { return true }

const ModuleMarker = (props) => {
    return (
        <MyMarker {...props} icondef={getIcon()} />
    )
}

const getIcon = () => {
    return {
        url: "/mapicons/speedcamera/speedenforcementcamera.png",
        anchor: new window.google.maps.Point(13,12),
        scaledSize: new window.google.maps.Size(23, 32)
    };
}

const CardContent = (props) => {
    const obj = props.obj;
    return (
        <>
            <CameraLocation value={obj} />
            <MonitoredApproaches value={obj} />
        </>
    )
}

const CameraLocation = props => {
    const camera = props.value;
    if(camera.startLocation.route.trim() != "") {
        return (
            <Box sx={{ fontWeight: 'bold'}}>{camera.startLocation.route}</Box>
        )
    } else {
        return (
            <Box sx={{ fontWeight: 'bold'}}>{camera.camera.description}</Box>
            )
    }
}

const InfoWindow = (props) => {
    const module = props.module;
    const obj = props.icon;
    const MyCardContent = module.cardContent;
    const showCardContent = module.showCardContent;

    return (
        <div className="infoWindow center"><div>Speed Camera Location</div>
            {showCardContent(obj) ?
                <div className="spacer"><MyCardContent obj={obj} /></div>
                :
                null}
            <hr>
            </hr><CameraInstalled icon={obj} /></div>
    )
}

export default {
    name: 'Speed Cameras',
    label: 'Speed Camera #',
    marker: ModuleMarker,
    showOnMap: true, //show on map layer filter
    mapLayerIcon: "/mapicons/speedcamera/speedenforcementcamera.png",
    showOnSidebar: false,
    sideBarCard: ObjectCard,
    endpoint: endpoint,
    defaultOn: false,
    updateDelay: 0,
    showCardContent: showCardContent,
    cardContent: CardContent,
    infoWindow: InfoWindow,
    publishLayer: "tasWebapp.speedcameras.enable"
}